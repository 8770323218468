const companyControllerMixin = {
    methods: {
        save(form) {
            return this.axios.post(this.$urls.company.companies.url, form);
        },
        saveContact(companyId, form) {
            return this.axios.post(this.$urls.portal.updateContact.url.replace(':id', companyId), form);
        },
        addDocument(form) {
            return this.axios.post(this.$urls.portal.addDocument.url, form, {
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${ form.boundary }`
                }
            });
        },
        addRequisites(form) {
            return this.axios.post(this.$urls.portal.addBankRequisites.url, form);
        },
        addServices(form) {
            return this.axios.post(this.$urls.portal.addServices.url, form);
        },
        addServiceOptions(form) {
            return this.axios.post(this.$urls.portal.addServiceOptions.url, form);
        },
        addCountries(form) {
            return this.axios.post(this.$urls.portal.addCountries.url, form);
        }
    }
};

export default companyControllerMixin;