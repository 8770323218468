<template>
  <div :class="addClass">
    <div class="form-group">
      <label class="form__label form__label--block">{{ getTranslation(formLabel) }} <span v-if="required" class="required">*</span></label>
      <span v-if="description" class="text text-muted">{{ getTranslation(description) }}</span>
      <v-select
          ref="citySelect"
          v-model="item_id"
          :options="items"
          :reduce="option=>option.id"
          @search:blur="!item_id ? customValidator.item_id = false : customValidator.item_id = true"
          :class="{ 'is-invalid': !customValidator.item_id && required}"
          :placeholder="getTranslation('selectFromList')"
          label="fullText"
      />
      <div v-if="required && !customValidator.item_id" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CitySelect",
  props: {
    editItemId: {
      required: false
    },
    required: {
      type: Boolean
    },
    countryId: {
      required: false
    },
    description: {
      type: String,
      required: false
    },
    addClass: String,
    formLabel: {
      type: String,
      default: 'city'
    }
  },
  data() {
    return {
      items: [],
      item_id: '',
      timer: null,
      showSelect: false,
      customValidator: {
        item_id: true,
      },
    }
  },
  watch: {
    'item_id'(id) {

      this.$emit('input', id);

      if ( !id ) {
        this.fetchItems();
      }
    },
    'countryId'(val) {
      this.fetchItems();
    }
  },
  created() {
    this.fetchItems().then(() => {
      if ( this.editItemId )
        this.item_id = this.editItemId;
    })
  },
  mounted() {
    let searchInput = this.$refs.citySelect.$el.getElementsByClassName('vs__search');
    let searchText = '';

    if ( searchInput.length > 0 ) searchInput[0].oninput = e => {
      if ( this.timer ) clearTimeout(this.timer);
      if ( searchText !== e.target.value ) {
        this.timer = setTimeout(() => {
          searchText = e.target.value;
          this.searchItem(searchText);
          this.timer = null;
        }, 500);
      }
    };
  },
  methods: {

    fetchItems() {

      let params = {
        count: 50
      };

      if ( this.countryId )
        params.country_id = this.countryId;

      return this.axios.get(this.$urls.portal.cities.url, { params: params }).then(response => {
        this.items = response.data.data.items;
      }).catch(()=>{});
    },
    searchItem(query) {

      let params = {};

      if ( query ) params.query = query;
      if ( this.countryId ) params.country_id = this.countryId;

      return this.axios.get(this.$urls.portal.cities.url, { params: params })
          .then(response => {
            this.items = response.data.data.items;
          }).catch(()=>{});
    },
    // We will call this method from parent component for check validity
    checkValidity() {
      if ( !this.item_id ) {
        this.customValidator.item_id = false;
        return false;
      }

      return true;
    }
  }
}
</script>

<style scoped>

</style>