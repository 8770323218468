<template>
  <section class="special_services secpadd">
    <div class="container">
      <div class="row quote1top">
        <div class="col-md-8">
          <div class="fh-section-title clearfix  text-left version-dark paddbtm40">
            <h2>{{ $store.getters['heading/getPortalHeadings'].title }}</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-sm-12">
          <div v-if="alert.show" class="alert alert-dismissible" role="alert" :class="{'alert-success':alert.success,'alert-danger':alert.danger}">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="resetAlert"><span aria-hidden="true">&times;</span></button>
            <p>{{ getTranslation(alert.msg) }}</p>
          </div>
          <ul class="nav nav-tabs nav-justified">
            <li>
              <a :class="{'active':tabs.first}" href="javascript:void(0)" @click="tabSelect('first')">
                <span class="badge btn-circle mr-1" :class="{'bg-danger':tabs.first}">1</span> {{ getTranslation('generalInfo') }}
              </a>
            </li>
            <li>
              <a class="nav-link" :class="{'active':tabs.second,'disabled':false}" @click="tabSelect('second')" href="javascript:void(0)">
                <span class="badge btn-circle mr-1" :class="{'bg-danger':tabs.second}">2</span>{{ getTranslation('documents') }}
              </a>
            </li>
            <li>
              <a :class="{'active':tabs.third,'disabled':false}" @click="tabSelect('third')" href="javascript:void(0)">
                <span class="badge btn-circle mr-1" :class="{'bg-danger':tabs.third}">3</span>{{ getTranslation('requisites') }}
              </a>
            </li>
            <li>
              <a :class="{'active':tabs.fourth,'disabled':false}" @click="tabSelect('fourth')" href="javascript:void(0)">
                <span class="badge btn-circle mr-1" :class="{'bg-danger':tabs.fourth}">4</span>{{ getTranslation('companyCreateServices') }}
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <div class="tab-pane section" :class="{'show active': tabs.first}">
              <div class="row m-auto justify-content-between">
                <section class="section--shadow-0  col-md-7 col-sm-12">
                  <div class="section__header">
                    <h4 class="section__title">
                      {{ getTranslation('generalInfo') }}
                    </h4>
                  </div>
                  <div class="section__body fh-form request-form">
                    <div class="field">
                      <label>{{ getTranslation('companyName') }} <span class="required">*</span>
                      </label>
                      <input type="text" class="form-control" v-model.trim="form.title" :class="{'is-invalid':$v.form.title.$error}"
                             :placeholder="getTranslation('companyName')">
                      <div v-if="$v.form.title.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
                    </div>
                    <div class="field">
                      <label>{{ getTranslation('code') }}</label>
                      <input type="text" v-model.trim="form.code" :placeholder="getTranslation('code')">
                    </div>

                    <CountrySelect :required="true" :edit-country-id="form.country_id" v-model="form.country_id"/>

                    <CitySelect :edit-item-id="form.city_id" v-model="form.city_id" :country_id="form.country_id" :description="'companyCityDescription'"/>

                    <AirportSelect :edit-item-id="form.airport_id" v-model="form.airport_id" :description="'companyAirportDescription'"/>

                    <div class="field">
                      <label for="shortArea">{{ getTranslation('description') }}</label>
                      <textarea v-model.trim="form.description" id="shortArea" cols="30" rows="10"/>
                    </div>
                  </div>
                </section>
                <section class="section--shadow-0  col-md-4 col-sm-12">
                  <div class="section__header">
                    <h4 class="section__title">
                      {{ getTranslation('contacts') }}
                    </h4>
                  </div>
                  <div class="section__body fh-form request-form">
                    <div class="field">
                      <label>
                        {{ getTranslation('email') }} <span class="required">*</span>
                      </label>
                      <input type="email" class="form-control" v-model.trim="contactForm.email" :class="{'is-invalid':$v.contactForm.email.$error}"
                             :placeholder="getTranslation('email')">
                      <div v-if="$v.contactForm.email.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
                    </div>
                    <div class="field">
                      <label>
                        {{ getTranslation('mobile') }} <span class="required">*</span>
                      </label>
                      <div v-if="showPhonCodes" class="input-group">

                        <PhoneCode required :edit-item-id="contactForm.mobile_code_id" v-model="contactForm.mobile_code_id" :items="phoneCodes"/>

                        <input type="text" class="form-control" v-model.trim="contactForm.mobile" :class="{'is-invalid':$v.contactForm.mobile.$error}"
                               :placeholder="getTranslation('mobile')">
                      </div>
                      <div v-if="$v.contactForm.mobile.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
                    </div>
                    <div class="field">
                      <label>
                        {{ getTranslation('fax') }} <span class="required">*</span>
                      </label>
                      <input type="text" class="form-control" v-model.trim="contactForm.fax" :class="{'is-invalid':$v.contactForm.fax.$error}"
                             :placeholder="getTranslation('fax')">
                      <div v-if="$v.contactForm.fax.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
                    </div>
                    <div class="field">
                      <label for="address">{{ getTranslation('address') }}<span class="required">*</span></label>
                      <textarea v-model.trim="contactForm.address" :class="{'is-invalid':$v.contactForm.address.$error}" class="form-control" id="address" cols="30" rows="10"/>
                      <div v-if="$v.contactForm.address.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
                    </div>
                    <div class="field">
                      <label for="company_address">{{ getTranslation('legalAddress') }}<span class="required">*</span></label>
                      <textarea v-model.trim="contactForm.company_address" :class="{'is-invalid':$v.contactForm.company_address.$error}" class="form-control" id="company_address" cols="30"
                                rows="10"/>
                      <div v-if="$v.contactForm.company_address.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
                    </div>
                  </div>
                </section>
              </div>
              <div>
                <button type="button" @click.prevent="saveCompany" class="btn btn-lg btn-primary">{{ getTranslation('next') }}</button>
                <router-link :to="{name:'company.getList'}" class="btn btn-lg btn-secondary ">{{ getTranslation('cancel') }}</router-link>
              </div>
            </div>
            <div class="tab-pane section" :class="{'show active': tabs.second}">
              <div class="row m-auto justify-content-between">
                <section class="section--shadow-0  col-md-6 col-sm-12">
                  <div class="section__header">
                    <h4 class="section__title">
                      {{ getTranslation('documents') }}
                    </h4>
                  </div>
                  <div class="section__body fh-form request-form">
                    <div class="form-group">
                      <label class="form__label--block form__label">{{ getTranslation('document') }} <span class="required">*</span>
                      </label>
                      <VSelect
                          :options="formOptions.documentTypes"
                          :reduce="option=>option.id"
                          v-model="document.type_id"
                          label="title"
                          :class="{ 'is-invalid': $v.document.type_id.$error }"
                          :placeholder="getTranslation('selectFromList')"
                      />
                      <div v-if="$v.document.type_id.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
                    </div>
                    <div class="form-group">
                      <label class="form__label--block form__label">{{ getTranslation('file') }} <span class="required">*</span>
                      </label>
                      <input @change="uploadFile($event)" type="file" class="form-control-file"
                             :class="{'is-invalid':$v.document.file}">
                      <div v-if="$v.document.type_id.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
                    </div>
                    <div class="form-group">
                      <label class="form__label--block form__label">{{ getTranslation('description') }}
                      </label>
                      <textarea v-model.trim="document.description" class="form-control" cols="30" rows="10"/>
                    </div>
                    <div class="form-group">
                      <div class="section__controls-group">
                        <button type="button" @click.prevent="sendDocument" class="btn btn-lg btn-primary">{{ getTranslation('saveDocument') }}</button>
                      </div>
                    </div>
                  </div>
                </section>
                <section class="section--shadow-0  col-md-5 col-sm-12">
                  <div class="section__header">
                    <h4 class="section__title">
                      {{ getTranslation('documents') }}
                    </h4>
                  </div>
                  <div class="section__body">
                    <p v-if="documents.length < 1" class="section__descr">{{ getTranslation('notAddedYet') }}</p>
                    <DocumentsComponent v-else :documents="documents" title-class="font-sm"></DocumentsComponent>
                  </div>
                </section>
              </div>
              <div class="row m-auto justify-content-end">
                <div class="mt-5 section__controls">
                  <div class="section__controls-group">
                    <button type="button" @click.prevent="()=>{activateStep('third');tabSelect('third')}" class="btn btn-lg btn-primary">{{ getTranslation('next') }}</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane section" :class="{'show active': tabs.third}">
             <div class="row m-auto">
               <section class="col-md-12 col-sm-12">
                 <div class="section__header">
                   <h4 class="section__title">
                     {{ getTranslation('requisites') }}
                   </h4>
                 </div>
                 <div class="section__body fh-form request-form">
                   <div class="row m-auto justify-content-between">
                     <div class="col-md-6 col-sm-12">
                       <div class="form-group">
                         <label class="form__label--block form__label">{{ getTranslation('bankName') }} <span class="required">*</span>
                         </label>
                         <input type="text" class="form-control" v-model.trim="requisite.bank_name" :class="{'is-invalid':$v.requisite.bank_name.$error}"
                                :placeholder="getTranslation('bankName')">
                         <div v-if="$v.requisite.bank_name.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
                       </div>
                       <div class="form-group">
                         <label class="form__label--block form__label">{{ getTranslation('bik') }}
                         </label>
                         <input type="text" class="form-control" v-model.trim="requisite.bik" :placeholder="getTranslation('bik')">
                       </div>
                       <div class="form-group">
                         <label class="form__label--block form__label">{{ getTranslation('swift') }} <span class="required">*</span>
                         </label>
                         <input type="text" class="form-control" v-model.trim="requisite.swift" :class="{'is-invalid':$v.requisite.swift.$error}"
                                :placeholder="getTranslation('swift')">
                         <div v-if="$v.requisite.swift.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
                       </div>
                       <div class="form-group">
                         <label class="form__label--block form__label">{{ getTranslation('multiCurrency') }}</label>
                         <input type="text" class="form-control" v-model.trim="requisite.multi" :placeholder="getTranslation('multiCurrency')">
                       </div>
                     </div>
                     <div class="col-md-6 col-sm-12">
                       <div class="form-group">
                         <label class="form__label--block form__label">{{ getTranslation('USD') }} <span class="required">*</span>
                         </label>
                         <input type="text" class="form-control" v-model.trim="requisite.USD" :class="{'is-invalid':$v.requisite.USD.$error}"
                                :placeholder="getTranslation('USD')">
                         <div v-if="$v.requisite.USD.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
                       </div>
                       <div class="form-group">
                         <label class="form__label--block form__label">{{ getTranslation('KGS') }}
                         </label>
                         <input type="text" class="form-control" v-model.trim="requisite.KGS" :placeholder="getTranslation('KGS')">
                       </div>
                       <div class="form-group">
                         <label class="form__label--block form__label">{{ getTranslation('RUB') }}
                         </label>
                         <input type="text" class="form-control" v-model.trim="requisite.RUB" :placeholder="getTranslation('RUB')">
                       </div>
                       <div class="form-group">
                         <label class="form__label--block form__label">{{ getTranslation('EUR') }}
                         </label>
                         <input type="text" class="form-control" v-model.trim="requisite.EUR" :placeholder="getTranslation('EUR')">
                       </div>
                     </div>
                   </div>
                 </div>
               </section>
             </div>
              <div class="row m-auto justify-content-end">
                <div class="mt-5 section__controls">
                  <div class="section__controls-group">
                    <button type="button" @click.prevent="saveRequisites" class="btn btn-lg btn-primary">{{ getTranslation('next') }}</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane section" :class="{'show active': tabs.fourth}">
              <div class="row m-auto justify-content-between">
                <section class="col-md-12 col-sm-12">
                  <div class="section__header">
                    <h4 class="section__title">
                      {{ getTranslation('services') }}
                    </h4>
                  </div>
                  <div class="section__body fh-form request-form">
                    <div class="row">
                      <div class="col-sm-12 col-md-6">
                        <div class="form-group">
                          <label class="form__label--block form__label">{{ getTranslation('serviceCategory') }} <span class="required">*</span>
                          </label>
                          <VSelect
                              :options="formOptions.services"
                              :reduce="option=>option.id"
                              v-model="servicesForm.services"
                              :multiple="true"
                              label="title"
                              :class="{ 'is-invalid': $v.servicesForm.services.$error }"
                              :placeholder="getTranslation('selectFromList')"
                          />
                          <div v-if="$v.servicesForm.services.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                          <label class="form__label--block form__label">{{ getTranslation('serviceOptions') }} <span class="required">*</span>
                          </label>
                          <VSelect
                              :options="formOptions.serviceOptions"
                              :reduce="option=>option.id"
                              v-model="serviceOptionsForm.serviceOptions"
                              :multiple="true"
                              label="full_text"
                              :class="{ 'is-invalid': $v.serviceOptionsForm.serviceOptions.$error }"
                              :placeholder="getTranslation('selectFromList')"
                          />
                          <div v-if="$v.serviceOptionsForm.serviceOptions.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div class="row m-auto justify-content-end">
                <div class="mt-5 section__controls">
                  <div class="section__controls-group">
                    <button type="button" @click.prevent="saveServices" class="btn btn-lg btn-primary">{{ getTranslation('save') }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import required from "vuelidate/lib/validators/required";
import CountrySelect from "@/components/shared/CountrySelect";
import CitySelect from "@/components/shared/CitySelect";
import AirportSelect from "@/components/shared/AirportSelect";
import companyControllerMixin from "@/views/admin/companies/mixins/CompanyController";
import DocumentsComponent from "@/components/shared/DocumentsComponent";
import companyActionMixin from "@/views/admin/companies/mixins/companyAction";
import phoneMixin from "@/components/mixins/PhoneCode";
import PhoneCode from "@/components/shared/PhoneCode";

export default {
  name: "Create",
  components: { PhoneCode, DocumentsComponent, AirportSelect, CitySelect, CountrySelect },
  mixins: [ companyControllerMixin, companyActionMixin, phoneMixin ],
  data() {
    return {
      alert: {
        show: false,
        success: false,
        danger: false,
        msg: '',
      },
      tabs: {
        first: true,
        second: false,
        third: false,
        fourth: false
      },
      tabsDone: {
        first: false,
        second: false,
        third: false,
        fourth: false
      },
      form: {
        id: null,
        title: '',
        code: '',
        description: '',
        system_default: false,
        country_id: '',
        city_id: '',
        airport_id: '',
      },
      contactForm: {
        id: null,
        email: '',
        phone: '',
        phone_code_id: 118,
        fax: '',
        mobile: '',
        mobile_code_id: 118,
        website: '',
        postal_code: '',
        address_url: '',
        address: '',
        company_address: ''
      },
      documents: [],
      document: {
        company_id: '',
        type_id: '',
        title: '',
        description: '',
        file: ''
      },
      formData: {},
      requisite: {
        inn: '',
        bik: '',
        ogrn: '',
        okpo: '',
        bank_posrednik: '',
        bank_korrespondent: '',
        swift: '',
        bank_name: '',
        multi: '',
        KGS: '',
        USD: '',
        RUB: '',
        EUR: '',
        company_id: '',
      },
      servicesForm: {
        services: [],
        company_id: '',
      },
      serviceOptionsForm: {
        serviceOptions: [],
        company_id: '',
      }
    }
  },
  created() {
    this.fetchFormOptions();
    this.setPortalHeadings({
      title: this.getTranslation('registerNewShipper'),
      breadcrumbs: [
        {
          label: this.getTranslation('home'),
          route: '/'
        },
        {
          label: this.getTranslation('registration'),
          route: { name: 'portal.register' }
        },
        {
          label: this.getTranslation('registerNewShipper')
        }
      ]
    });
  },
  methods: {
    saveCompany() {

      this.$v.form.$touch();
      this.$v.contactForm.$touch();

      if ( this.$v.form.$invalid )
        return;

      if ( this.$v.contactForm.$invalid )
        return;

      this.form.contact = { ...this.contactForm };

      this.axios.post(this.$urls.portal.registerCompany.url, this.form).then(response => {


        this.form.id = response.data.data.item.id;

        this.snotifySuccess('saveSuccessMsg');

        setTimeout(() => {
          this.activateStep('first');
          this.tabSelect('second');
        }, 2000);

      }).catch(() => {
      });

    },

    sendDocument() {

      if ( !this.form.id ) {
        this.alert.show = true;
        this.alert.danger = true;
        this.alert.msg = 'addGeneralInfo';
      }

      this.addDocument(this.formData).then(response => {
        this.documents.push(response.data.data);
        this.$v.document.$reset();
        this.snotifySuccess('saveSuccessMsg');

      }).catch(() => {

      });
    },

    uploadFile(e) {
      if ( e.target.files.length > 0 && e.target.files[0] ) {

        let file = e.target.files[0];

        let form = new FormData;

        form.append('file', file);

        this.document.company_id = this.form.id;
        this.document.file = file;
        this.$v.document.$touch();

        if ( !this.document.company_id ) {
          this.alert.show = true;
          this.alert.danger = true;
          this.alert.msg = 'addGeneralInfo';
          return;
        }

        if ( this.$v.document.$invalid )
          return;

        for ( let prop in this.document ) {
          form.append(prop, this.document[prop]);
        }

        this.formData = form;

      }
    },

    saveRequisites() {

      this.requisite.company_id = this.form.id;

      if ( !this.form.id ) {
        this.alert.show = true;
        this.alert.danger = true;
        this.alert.msg = 'addGeneralInfo';
      }

      this.$v.requisite.$touch();

      if ( this.$v.requisite.$invalid )
        return;

      this.addRequisites(this.requisite)
          .then(response => {
            setTimeout(() => {
              this.activateStep('fourth');
              this.tabSelect('fourth');
              this.resetAlert();

            }, 2000);
          }).catch(() => {

      });

    },

    saveServices() {
      this.servicesForm.company_id = this.form.id;
      this.serviceOptionsForm.company_id = this.form.id;

      if ( !this.form.id ) {
        this.alert.show = true;
        this.alert.danger = true;
        this.alert.msg = 'addGeneralInfo';
      }

      this.$v.servicesForm.$touch();
      this.$v.serviceOptionsForm.$touch();

      if ( this.$v.servicesForm.$invalid || this.$v.serviceOptionsForm.$invalid )
        return;

      this.addServices(this.servicesForm)
          .then(() => {
            this.addServiceOptions(this.serviceOptionsForm)
                .then(() => {

                  Swal.fire({
                    icon: 'success',
                    title: this.getTranslation('Your company is successfully saved, our moderator will contact you'),
                  });

                  this.$router.push({ path: '/' }).catch(() => {
                  });

                  this.resetAlert();
                }).catch(() => {
            });
          }).catch(() => {

      });
    },

    goHome() {
      this.$router.push({ path: '/' }).catch(() => {
      });
    },

    resetDocument() {
      this.document = {
        company_id: '',
        type_id: '',
        title: '',
        description: '',
        file: ''
      };
      this.formData = {};
    },
    activateStep(step) {
      this.tabsDone[step] = true;
    },
    resetAlert() {
      this.alert.show = false;
      this.alert.success = false;
      this.alert.danger = false;
      this.alert.msg = '';

    }
  },
  validations: {
    servicesForm: {
      company_id: {
        required
      },
      services: {
        required
      }
    },
    serviceOptionsForm: {
      company_id: {
        required
      },
      serviceOptions: {
        required
      }
    },
    form: {
      title: {
        required
      },
      country_id: {
        required
      }
    },
    requisite: {
      swift: {
        required
      },
      bik: {
        required
      },
      bank_name: {
        required
      },
      USD: {
        required
      },
      company_id: {
        required
      }
    },
    contactForm: {
      email: {
        required
      },
      mobile: {
        required
      },
      company_address: {
        required
      },
      address: {
        required
      },
      fax: {
        required
      }
    },
    document: {
      type_id: {
        required
      },
      company_id: {
        required
      },
      file: {
        required
      }
    }
  }
}
</script>

<style scoped>
.nav {
  display: -ms-flexbox;
  display: -webkit-box;
  display: block !important;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap !important;
  margin-bottom: 0;
  list-style: none;
}
</style>