<template>
  <div :class="addClass">
    <div class="form-group">
      <label class="form__label form__label--block">{{ getTranslation(formLabel) }} <span v-if="required" class="required">*</span></label>
      <span v-if="description" class="text text-muted">{{ getTranslation(description) }}</span>
      <v-select
          ref="airportSelect"
          v-model="item_id"
          :options="items"
          :reduce="option=>option[reduceOption]"
          :class="{ 'is-invalid': !customValidator.item_id && required}"
          :placeholder="getTranslation(placeholder)"
          label="fullText"
          :multiple="multiple"
      />
      <div v-if="required && !customValidator.item_id" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AirportSelect",
  props: {
    reduceOption:{
      type:String,
      default:'id'
    },
    editItemId: {
      required: false
    },
    required: {
      type: Boolean,
      default: false
    },
    description: {
      type: String
    },
    addClass: String,
    formLabel: {
      type: String,
      default: 'airport'
    },
    placeholder:{
      type:String,
      default:'selectFromList'
    },
    multiple: Boolean,
  },
  data() {
    return {
      items: [],
      item_id: '',
      timer: null,
      showSelect: false,
      customValidator: {
        item_id: true,
      },
    }
  },
  watch: {

    'item_id'(id) {

      this.$emit('input', id);

      if ( !id ) {
        this.fetchItems();
      }
    },
    'editItemId'(val)
    {
      if ( val )
        this.item_id = val;
    }
  },

  created() {
    this.fetchItems().then(() => {
      if ( this.editItemId )
        this.item_id = this.editItemId;
    })
  },
  mounted() {
    let searchInput = this.$refs.airportSelect.$el.getElementsByClassName('vs__search');
    let searchText = '';

    if ( searchInput.length > 0 ) searchInput[0].oninput = e => {
      if ( this.timer ) clearTimeout(this.timer);
      if ( searchText !== e.target.value ) {
        this.timer = setTimeout(() => {
          searchText = e.target.value;
          this.searchItem(searchText);
          this.timer = null;
        }, 500);
      }
    };
  },
  methods: {

    fetchItems() {
      return this.axios.get(this.$urls.portal.airports.url, { params: { count: 50 } }).then(response => {
        this.items = response.data.data.items;
      }).catch(()=>{});
    },
    searchItem(query) {
      let params = {};
      if ( query ) params.query = query;

      return this.axios.get(this.$urls.portal.airports.url, { params: params })
          .then(response => {
            this.items = response.data.data.items;
          }).catch(()=>{});
    },
    // We will call this method from parent component for check validity
    checkValidity() {
      if ( !this.item_id ) {
        this.customValidator.item_id = false;
        return false;
      }

      return true;
    }
  }
}
</script>

<style scoped>

</style>