import moment from 'moment';

const documentMixin = {
    data() {
        return {
            formats: {
                pdf: '.pdf',
                excel: '.xlsx',
                word: '.docx'
            },
        }
    },
    methods: {
        createDownloadLink(response, report, format) {

            if ( !format.includes('.') )
                format = '.' + format;

            let blob;

            if ( this.isBinary(format) )
                blob = new Blob([ response.data ]);
            else if ( this.isPdf(format) )
                blob = new Blob([ response.data ], { type: 'application/pdf' });

            let url = window.URL.createObjectURL(blob);


            const link = document.createElement('a');

            link.href = url;

            link.setAttribute('download', this.getTranslation(report) + '-' + moment().format('DD.MM.YYYY HH-mm') + format);

            document.body.appendChild(link);

            link.click();
        },

        isBinary(format) {
            return format !== this.formats.pdf;
        },

        isPdf(format) {
            return format === this.formats.pdf;
        },

        getAxiosConfig(filterForm) {

            let config = {
                responseType: 'json'
            };

            let params = this.cleanObject(filterForm);


            if ( params.hasOwnProperty('download') ) {
                if ( params.download === true )
                    params.download = 1;
                else
                    params.download = 0;
            }

            config.params = params;

            if ( params.hasOwnProperty('format') )
                if ( this.isBinary(params.format) )
                    config.responseType = 'blob';
                else if ( this.isPdf(params.format) )
                    config.responseType = 'arraybuffer';


            return config;
        },
    }
};
export default documentMixin;
