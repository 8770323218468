const companyActionMixin = {
    data() {
        return {
            formOptions: {
                documentTypes: [],
                services: [],
                serviceOptions: []
            }
        }
    },
    methods: {
        fetchFormOptions() {
            return this.axios.get(this.$urls.company.formOptions.url)
                .then(response => {
                    this.formOptions.documentTypes = response.data.data.documentTypes;
                    this.formOptions.serviceOptions =  response.data.data.serviceOptions.map(item => {
                        item.airTitle = item.title.air;
                        item.landTitle = item.title.land;

                        return item;
                    });
                    this.formOptions.services = response.data.data.services;
                }).catch(() => {
                });
        },

    }
};

export default companyActionMixin;