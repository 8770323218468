<template>
  <div class="documents-component">
    <div class="row">
      <div v-for="item in items" v-bind:key="item.id" :class="addClasses">
        <div class="card">
          <div class="card-header">
            <div class="card-header-action float-right" >
              <button type="button" class="btn btn-danger btn-sm" style="font-size: 13px" >
                <font-awesome-icon :icon="'trash-alt'" />
              </button>
            </div>
            <div class="document-icon">
              <font-awesome-icon :icon="item.icon"></font-awesome-icon>
            </div>
          </div>
          <div class="card-body">
            <h5 v-if="item.type.title" class="card-title" :class="titleClass">
              <a href="javascript:void(0)" @click="download(item)">{{ item.type.title }}</a>
            </h5>
            <p v-if="item.description" class="card-text" :class="descriptionClass">{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <LightBox
        v-if="images.length > 0"
        ref="lightBoxGallery"
        :show-caption="true"
        :show-light-box="false"
        :media="images"
    ></LightBox>
  </div>
</template>

<script>
import LightBox from 'vue-image-lightbox'
import documentMixin from "@/components/mixins/document";

export default {
  name: "DocumentsComponent",
  components: { LightBox },
  mixins: [ documentMixin ],
  props: {
    documents: {
      type: Array,
      default: () => {
        return [];
      }
    },
    addClasses: {
      type: String,
      default: 'col-md-6 col-sm-6'
    },
    titleClass: [ Object, String ],
    descriptionClass: [ Object, String ],
    downloadUrl: String
  },
  data() {
    return {
      items: [],
      images: []
    }
  },
  created() {
    this.addFaIcon();
  },

  watch: {
    'documents'() {
      this.addFaIcon();
    }
  },

  methods: {
    addFaIcon() {
      this.items = this.documents.map(item => {
        switch ( item.file.extension ) {
          case "pdf":
            item.icon = 'file-pdf';
            break;
          case "docx":
            item.icon = 'file-word';
            break;
          case "xlsx":
          case "xls":
            item.icon = 'file-excel';
            break;
          case "png":
          case "jpg":
          case "jpeg":
            item.icon = 'file-image';
            break;
        }

        return item;
      })
    },
    download(item) {

      if ( !this.downloadUrl )
        return;

      this.axios.get(this.downloadUrl, this.getAxiosConfig({ format: item.file.extension, document_id: item.id }))
          .then(response => {
            this.createDownloadLink(response, item.type.title, item.file.extension);
          }).catch(() => {
      });

    }
  }

}
</script>

<style scoped>
.document-icon {
  font-size: 90px !important;
}
</style>